<template>
  <div class="projects">
    <div class="designer" v-for="designer in items" :key="designer">
      <div class="designer-name">{{ designer.designer }}</div>
      <div class="designer-projects">
        <div class="designer-project" v-for="project in designer.projects" :key="project.id">
          <div class="designer-project-caption">{{ project.caption }}</div>
          <div class="designer-project-days"
            :class="{ 'green': restDays(project.end_date) > 0, 'red': restDays(project.end_date) < 0 }">{{
              Math.abs(restDays(project.end_date)) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as dayjs from 'dayjs';

export default {
  name: 'ProjectsSlideType',
  props: ['items'],
  methods: {
    restDays(value) {
      return dayjs(value).diff(dayjs(), 'day')
    }
  }
}
</script>

<style lang="scss" scoped>
.projects {
  display: flex;
  margin: 20px;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  .designer {
    display: flex;
    flex: 1 1 20%;
    flex-direction: column;
    color: #d1d4dc;

    .designer-name {
      font-size: 30px;
      padding-bottom: 10px;
      display: inline-flex;
      font-weight: bold;

      &:after {
        content: '';
        border-bottom: 2px solid #d1d4dc;
      }
    }

    .designer-projects {
      font-size: 24px;
      line-height: 2.5;

      .designer-project {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 5px;

        .designer-project-caption,
        .designer-project-days {
          display: flex;
        }

        .designer-project-caption {
          flex: 1 1 70%;
        }

        .designer-project-days {
          flex: 1 1 30%;
          justify-content: center;
          font-weight: bold;

          &.red {
            color: #f23645;
          }

          &.green {
            color: #089981;
          }
        }
      }
    }
  }
}
</style>