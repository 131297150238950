import { createApp } from "vue";
import App from "./App.vue";
import ActionCableVue from "actioncable-vue";
import router from "./router";

import "./assets/main.css";
import "./assets/fonts/stylesheet.css";

const actionCableVueOptions = {
  debug: true,
  debugLevel: "error",
  connectionUrl:
    (process.env.NODE_ENV == "production"
      ? "wss://tverstroyka.wopa.agency"
      : "ws://127.0.0.1:3000") + "/cable",
  connectImmediately: true,
};

createApp(App)
  .use(router)
  .use(ActionCableVue, actionCableVueOptions)
  .mount("#app");
