<template>
  <div class="paginate">
    <div class="slide-count">{{ page }} &horbar; {{ slidesCount }}</div>
    <!-- <div v-for="index in slidesCount" :key="index" :class="{ 'active': index == page }" class="paginate-dot">
      <span :style="'transition-duration:' + time / 1000 + 's'"></span>
    </div> -->

    <div class="paginator-wrapper">
      <div class="paginator-content" :style="{ 'animation-duration': time / 1000 + 's' }"></div>
    </div>
    <div class="slide-title">{{ slideTitle }}</div>
  </div>
</template>

<script>
export default {
  name: 'SlidePaginator',
  props: ['slidesCount', 'page', 'time', 'slideTitle']
}
</script>

<style>
@keyframes paginator {
  0% {
    width: 100%;
    background-color: #089981;
  }

  50% {
    background-color: #f7cdd1;
  }

  100% {
    width: 0;
    background-color: #f23645;
  }
}

</style>

<style scoped lang="scss">.paginate {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 20px 0 0;

  .paginate-dot {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #dc312c;
    // span {
    //   background-color: #d1d4dc;

    // }
    span {
      height: 100%;
      width: 0%;
      display: block;
      transition: width 3s linear;
    }

    &.active {
      background-color: #d1d4dc;

      &~.paginate-dot {
        background-color: #d1d4dc;
      }

      span {
        width: 100%;
        background-color: #dc312c;
      }
    }
  }

  .slide-title,
  .slide-count {
    position: absolute;
    color: #d1d4dc;
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;
    opacity: 0.5;
    top: 10px;
  }

  .slide-title {
    right: 20px;
  }

  .slide-count {
    left: 20px;
  }

  .paginator-wrapper {
    width: 200px;
    height: 10px;
    display: flex;
    justify-content: center;
  }

  .paginator-content {
    width: 100%;
    height: 100%;
    animation-name: none;
    &.move, &.move2 {
      animation-name: paginator;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      border-radius: 5px;
    }
  }
}</style>