<template>
  <div class="cards">
    <div v-for="item in items" :key="item.caption" class="card">
      <div class="card-title" v-html="formatCaption(item)"></div>
      <div class="card-info" :class="{ 'up': item.abs_change > 0, 'down': item.abs_change < 0 }">
        <div class="card-price">
          {{ formatPrice(item.price) }}
          <span>{{ item.abs_change > 0 ? '&#9650;' : '' }}
            {{ item.abs_change < 0 ? '&#9660;' : '' }}</span>
        </div>
        <div class="card-changes">
          <div class="card-abs-change">{{ formatChangePrice(item.abs_change) }}</div>
          <div class="card-change">{{ item.change > 0 ? '+' : '' }}{{ item.change != null ? item.change : '+100' }}%</div>
          <div class="card-days-ago">{{ daysAgo(item) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import priceFormats from '@/common/priceFormats.js';
import * as dayjs from 'dayjs'

export default {
  name: 'MaterialsSlideType',
  props: ['items'],
  mixins: [priceFormats],
  methods: {
    formatCaption(item) {
      let caption = item.caption;
      if (item.group == 'Квадратный метр')
        caption = caption.split('/').join('<br>');

      if (item.unit)
        caption += `, ${item.unit}`;

      return caption;
    },
    daysAgo: function (item) {
      if(item.last_update == null) {
        return "∞"
      }
      return dayjs().diff(dayjs(item.last_update), 'day');
    }
  }
}
</script>