<template>
  <div class="dashboard" :class="'template' + template">
    <div class="materials" v-if="!init">
      <SlidePaginator
        :slidesCount="slidesCount"
        :page="page"
        :time="time"
        :slideTitle="slideTitle" />

      <div class="slide" v-for="(slide, index) in slides" :key="index" v-show="page == index + 1">

        <MaterialsSlideType :items="slide.data" v-if="slide.type == 'materials'" :key="'slide_' + index" />

        <ProjectsSlideType :items="slide.data" v-if="slide.type == 'projects'" :key="'slide_' + index" />
      </div>
    </div>
    <div class="loading" v-if="init">
      <img src="@/assets/loading.gif" alt="">
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import MaterialsSlideType from '@/components/slideTypes/MaterialsSlideType.vue';
import ProjectsSlideType from '@/components/slideTypes/ProjectsSlideType.vue';
import SlidePaginator from '@/components/SlidePaginator.vue';

import priceFormats from '@/common/priceFormats.js';

export default {
  name: 'GridDisplay',
  components: {
    MaterialsSlideType,
    ProjectsSlideType,
    SlidePaginator
  },
  mixins: [priceFormats],
  data() {
    return {
      init: true,
      prices: [],
      page: 0,
      slides: [],
      slidesCount: 0,
      interval: null,
      time: 3000,
      template: 1
    }
  },
  channels: {
    SlidesChannel: {
      connected() {
        console.log('ws connected');
      },
      rejected() {
        console.log('ws rejected');
      },
      received() {
        this.loadPrices();
      },
      disconnected() {
        console.log('ws disconnected')
      },
    },
    ReloadChannel: {
      connected() {
        console.log('ws2 connected');
      },
      rejected() {
        console.log('ws2 rejected');
      },
      received() {
        document.location.reload();
      },
      disconnected() {
        console.log('ws2 disconnected')
      },
    }
  },
  mounted() {
    if (this.$route.params.time)
      this.time = this.$route.params.time;
    if (this.$route.params.template)
      this.template = this.$route.params.template;

    this.loadPrices();

    this.$cable.subscribe({
      channel: "SlidesChannel",
      room: "public",
    });

    this.$cable.subscribe({
      channel: "ReloadChannel",
      room: "public",
    });
  },
  methods: {
    loadPrices() {
      axios.get(`${process.env.VUE_APP_HOST}/api/v1/slides.json`, {
        auth: {
          username: 'tverstroyka',
          password: process.env.VUE_APP_HTTP_AUTH
        },
        params: {
          template: this.template
        }
      }).then((resp) => {
        this.init = false;
        this.slides = resp.data.slides;
        this.slidesCount = resp.data.slidesCount;

        clearInterval(this.interval);
        setTimeout(() => {
          this.interval = setInterval(() => this.nextPage(), this.time);
          this.page = 1;
          document
            .querySelector('.paginator-content')
            .classList
            .add('move');
        }, 500);
      })
    },
    nextPage() {
      this.page = this.page + 1;
      if (this.page > this.slidesCount) this.page = 1;
      if(document.querySelector('.paginator-content') != null) {
        document.querySelector('.paginator-content').classList.toggle('move');
        document.querySelector('.paginator-content').classList.toggle('move2');
      }
    }
  },
  computed: {
    slideTitle() {
      if(this.slides.length == 0 || this.page < 1) return '';

      return this.slides[this.page - 1].title;
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/card.scss';

.dashboard {
  height: 100%;
  width: 100%;

  &.template1 {
    .materials {
      height: 100%;

      .slide {
        height: calc(100% - 40px);

        .cards {
          grid-template-columns: 1fr 1fr;
          grid-template-rows: repeat(5, 1fr);
          max-height: calc(100% - 40px);
          height: 100%;

          .card {
            overflow: auto;
            padding: 10px;
          }
        }
      }
    }
  }

  &.template2 {
    .cards {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(7, min-content);
    }
  }

  &.template3 {
    .cards {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: repeat(5, min-content);
    }
  }
}

.cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(5, min-content);
  padding: 20px;
  gap: 20px;
  flex-wrap: wrap;
  max-height: calc(100% - 80px);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  img {
    height: 150px;
  }
}</style>
