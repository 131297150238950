import { createWebHistory, createRouter } from "vue-router";
import Grid from "@/views/GridDisplay.vue";

const routes = [
  {
    path: "/",
    name: "Grid",
    component: Grid,
  },
  {
    path: "/:template/:time?",
    name: "GridWithParams",
    component: Grid,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;