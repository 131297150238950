import currency from 'currency.js';

function removeZeroCents(currency, options) {
  if (currency.intValue % currency.p != 0)
    return options.format(currency, options)
  else
    return options.format(currency, Object.assign(options, { precision: 0 }))
}

let priceFormats = {
  methods: {
    formatChangePrice(value) {
      if (value == 0)
        return currency(value, { symbol: '₽', separator: ' ', pattern: '# !', negativePattern: '-# !', precision: 0 }).format();
      return currency(value, { symbol: '₽', separator: ' ', pattern: '+# !', negativePattern: '-# !' }).format(removeZeroCents);
    },
    formatPrice(value) {
      return currency(value, { symbol: '₽', separator: ' ', pattern: '# !', negativePattern: '- # !' }).format(removeZeroCents);
    },
  }
}

export default priceFormats;